import { useCallback } from "react"

import { useAppDispatch, useAppSelector } from "@redux/hooks"
import { resetValue, setNavigate } from "@redux/navigation/slicer"

const useNavigation = () => {
    const { from, to, redirect } = useAppSelector((state) => state.navigation)
    const dispatch = useAppDispatch()

    const updateNavigation = useCallback(
        (navigate: NavigationModel) => {
            dispatch(setNavigate(navigate))
        },
        [dispatch]
    )

    const resetNavigation = () => dispatch(resetValue())

    return {
        from,
        to,
        redirect,
        updateNavigation,
        resetNavigation
    }
}

export default useNavigation
