import axios from "axios"

import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

const EMAIL_WHITELIST = "trading.options@pasarfx.co.id"

async function fetchBalanceStockOptions() {
    const response = await axios({
        url: `/api/alpaca/v1/trading/accounts/4e5a1a0b-eed2-4767-a69e-305ee35b2b20/positions`,
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Basic Q0tMNkU0WVJVQ0ZSNEVGS1MwRDI6c2VoSjJKZ21lZkVhQjZvQjhFVVJJaE5OUEpzTUZlN0Q5MTJmUjltNg=="
        }
    })

    let balances = response.data.filter((f: any) => f.asset_class === "us_equity")
    balances = balances.map((b: any) => ({
        asset: b.symbol,
        available: b.qty_available,
        locked: b.qty - b.qty_available
    }))

    const usdBalances = await axios({
        url: `/api/alpaca/v1/trading/accounts/4e5a1a0b-eed2-4767-a69e-305ee35b2b20/account`,
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Basic Q0tMNkU0WVJVQ0ZSNEVGS1MwRDI6c2VoSjJKZ21lZkVhQjZvQjhFVVJJaE5OUEpzTUZlN0Q5MTJmUjltNg=="
        }
    })

    balances.push({
        free: usdBalances.data.option_buying_power,
        asset: "USD",
        locked: 0
    })

    console.log(balances)

    return balances
}
export const fetchBalancesStock = createAsyncThunk<BalancesStockAPI>("balances/fetchBalancesStock", async () => {
    const res = localStorage.getItem("__user_traits")
    if (res) {
        const user = JSON.parse(res)
        if (user.email === EMAIL_WHITELIST) {
            return fetchBalanceStockOptions()
        }
    }
    const response = await baseFetchApiV3({
        url: "/us-stock/balance",
        method: "GET"
    })

    return response
})
