/* eslint-disable no-useless-return */
import { HYDRATE } from "next-redux-wrapper"

import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { FetchMarketStockResponse, fetchStocks } from "./actions"

const marketStockSlicer = createSlice({
    name: "marketStock",
    initialState: {
        isLoading: false,
        stocks: null as StockModel[] | null
    },
    reducers: {
        updateMarketStocks: (state, action: PayloadAction<MarketStockSocketPayload>) => {
            if (!state.stocks) {
                return
            }

            const data = action.payload
            const index = state.stocks.findIndex((e) => e.code === data.c)

            if (!state.stocks[index]) {
                return
            }

            state.stocks[index].price = {
                open: data.o,
                high: data.h,
                low: data.l,
                close: data.cl,
                mid: data.mp,
                buy: data.bp,
                sell: data.sp,
                volume: data.v,
                changePercentage: {
                    default: data.cp
                }
            }
        }
    },
    extraReducers: {
        [fetchStocks.pending.type]: (state) => {
            state.isLoading = true
            state.stocks = null
        },
        [fetchStocks.fulfilled.type]: (state, action: PayloadAction<FetchMarketStockResponse>) => {
            state.stocks = action.payload.stocks.map((stock) => ({
                id: stock.id,
                name: stock.n,
                code: stock.cd,
                logo: stock.lg,
                symbol: stock.s,
                sorting: stock.sort,
                minimumTradeQuote: {
                    buy: stock.min_b,
                    sell: stock.min_s
                },
                quoteFee: stock.fee,
                estimationSlippage: stock.slip,
                price: {
                    open: stock.o,
                    high: stock.h,
                    low: stock.l,
                    close: stock.c,
                    mid: stock.mp,
                    buy: stock.bp,
                    sell: stock.sp,
                    volume: stock.v,
                    changePercentage: {
                        default: stock.cp
                    }
                },
                information: {
                    maxSupply: stock.ms,
                    circulatingSupply: stock.cs,
                    totalSupply: stock.ts,
                    marketCap: stock.c * stock.cs,
                    globalVolume: stock.gv
                },
                decimals: {
                    quote: stock.qd,
                    shares: stock.sd
                }
            }))
            state.isLoading = false
        },
        [fetchStocks.rejected.type]: (state) => {
            state.isLoading = false
            state.stocks = null
        },
        [HYDRATE]: (state, action) => {
            if (action.payload?.stocks?.stocks) {
                state.stocks = action.payload.stocks.stocks
            }
        }
    }
})

export const { updateMarketStocks } = marketStockSlicer.actions

export default marketStockSlicer
