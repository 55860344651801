/* eslint-disable import/prefer-default-export */
export const imageCompression = async (image: File) => {
    const { default: imageCompressionFunction } = await import("browser-image-compression")

    const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        maxIteration: 50
    }

    return imageCompressionFunction(image, options)
}
