import axios from "axios"

import { createAsyncThunk } from "@reduxjs/toolkit"

export const fetchContractOptionsActions = createAsyncThunk<ContractOptionsAPI, ContractOptionsPayload>(
    "contract/fetchContractOptions",
    async ({ expiration_date, type, underlying_symbol }) => {
        const params: object = {
            underlying_symbol,
            type,
            expiration_date
        }

        const response = await axios({
            url: "/api/alpaca/v1/options/contracts?limit=20",
            method: "GET",
            params,
            headers: {
                "Content-Type": "application/json",
                Authorization:
                    "Basic Q0tMNkU0WVJVQ0ZSNEVGS1MwRDI6c2VoSjJKZ21lZkVhQjZvQjhFVVJJaE5OUEpzTUZlN0Q5MTJmUjltNg=="
            }
        })

        return response.data?.option_contracts
    }
)
