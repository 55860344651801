import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { fetchCurrency } from "./actions"

const currencySlicer = createSlice({
    name: "curency",
    initialState: {
        currency: null as CurrencyModel | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchCurrency.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchCurrency.fulfilled.type]: (state, action: PayloadAction<CurrencyAPI>) => {
            state.currency = {
                bestAsk: action.payload.best_ask,
                bestBid: action.payload.best_bid,
                currency: action.payload.currency,
                id: action.payload.id,
                minimumTrade: action.payload.minimum_trade,
                symbol: action.payload.symbol
            }
            state.isLoading = false
        }
    }
})

export default currencySlicer
