import React from "react"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import tw, { styled } from "twin.macro"

import Link from "@components/Link"

const Wrapper = tw.div`
    hidden xl:flex
    items-center gap-8
    font-bold
`

interface MenuItemProps {
    active?: boolean
}

const MenuItem = styled.span<MenuItemProps>`
    ${tw`button-1 text-main dark:text-dark-main`}
    ${tw`hover:text-primary dark:text-white dark:hover:text-primary`}
    ${({ active }) => active && tw`text-primary dark:text-primary`}
`

const MainMenu: React.FC = () => {
    const { t } = useTranslation("components")
    const router = useRouter()

    const explorePath = ["/trade/", "/lightning/price/", "markets", "explore"] as const

    const currentPath = router.route
    const walletPath = currentPath.includes("/wallet")

    const isActiveExplorePath = explorePath.some((item) => currentPath.includes(item))

    return (
        <Wrapper>
            <MenuItem active={isActiveExplorePath}>
                <Link href='/markets'>{t("components:navbar.navs.explore")}</Link>
            </MenuItem>

            <MenuItem active={walletPath}>
                <Link href='/wallet'>{t("components:navbar.navs.balance")}</Link>
            </MenuItem>
        </Wrapper>
    )
}

export default MainMenu
