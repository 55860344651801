import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { fetchCancelOrderStock } from "./actions"

const cancelOrderStockSlicer = createSlice({
    name: "cancelOrderStock",
    initialState: {
        isLoading: false,
        orderId: null
    } as unknown as CancelOrderStockModel,
    reducers: {},
    extraReducers: {
        [fetchCancelOrderStock.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchCancelOrderStock.fulfilled.type]: (state, action: PayloadAction<CancelOrderStockModel>) => {
            state.isLoading = false
            state.orderId = action.payload.orderId
        },
        [fetchCancelOrderStock.rejected.type]: (state) => {
            state.isLoading = false
        }
    }
})

export default cancelOrderStockSlicer
