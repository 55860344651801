import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const fetchStatusMarketStock = createAsyncThunk<StatusMarketStockAPI>("status/statusMarketStock", async () => {
    const response = await baseFetchApiV3<StatusMarketStockAPI>({
        url: "/us-stock/status",
        method: "GET"
    })
    return response
})
