import { HYDRATE } from "next-redux-wrapper"

import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { fetchStockChart } from "./actions"

const stockChartSlicer = createSlice({
    name: "stockChart",
    initialState: {
        stockChart: null as number[][] | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchStockChart.pending.type]: (state) => {
            state.isLoading = true
            state.stockChart = null
        },
        [fetchStockChart.fulfilled.type]: (state, action: PayloadAction<number[][]>) => {
            state.stockChart = action.payload
            state.isLoading = false
        },
        [HYDRATE]: (state, action: PayloadAction<{ STOCKChart: { stockChart: number[][] } }>) => {
            if (action.payload?.STOCKChart?.stockChart) {
                state.stockChart = action.payload.STOCKChart.stockChart
                state.isLoading = false
            }
        }
    }
})

export default stockChartSlicer
