import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

interface Args {
    s: string
    f?: number
    start?: number
    end?: number
}

export const fetchStockChart = createAsyncThunk<number[][], Args>(
    "otcStock/fetchStockChart",
    async ({ f = 1440, s, end, start }) => {
        const response = await baseFetchApiV3({
            url: `/us-stock/chart`,
            method: "GET",
            params: {
                f,
                s,
                end,
                start
            }
        })

        return response.sort()
    }
)
