import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { orderStockMarketBuyAction, orderStockMarketSellAction } from "./actions"

const orderStockMarketSlicer = createSlice({
    name: "orderStockMarketresult",
    initialState: {
        orderStockMarketResult: null as OrderStockMarketResultModel | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [orderStockMarketBuyAction.pending.type]: (state) => {
            state.orderStockMarketResult = null
            state.isLoading = true
        },
        [orderStockMarketBuyAction.fulfilled.type]: (state, action: PayloadAction<OrderStockMarketResultApi>) => {
            state.orderStockMarketResult = {
                amount: action.payload.amount,
                price: action.payload.price,
                quote: action.payload.quote,
                created_at: action.payload.created_at,
                estimateQuantity: action.payload.estimate_quantity,
                estimateQuote: action.payload.estimate_quote,
                quantity: action.payload.quantity,
                side: action.payload.side,
                status: action.payload.status,
                symbol: action.payload.symbol,
                transactionId: action.payload.transaction_id
            }
            state.isLoading = false
        },
        [orderStockMarketSellAction.pending.type]: (state) => {
            state.orderStockMarketResult = null
            state.isLoading = true
        },
        [orderStockMarketSellAction.fulfilled.type]: (state, action: PayloadAction<OrderStockMarketResultApi>) => {
            state.orderStockMarketResult = {
                amount: action.payload.amount,
                price: action.payload.price,
                quote: action.payload.quote,
                created_at: action.payload.created_at,
                estimateQuantity: action.payload.estimate_quantity,
                estimateQuote: action.payload.estimate_quote,
                quantity: action.payload.quantity,
                side: action.payload.side,
                status: action.payload.status,
                symbol: action.payload.symbol,
                transactionId: action.payload.transaction_id
            }
            state.isLoading = false
        }
    }
})

export default orderStockMarketSlicer
