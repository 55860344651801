import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { SimulationModel } from "@type/model/simulation"

const simulationSlicer = createSlice({
    name: "simulation",
    initialState: {
        step: 1,
        balance: 5000,
        amount: 0,
        stock: {
            available: 0,
            shares: 0,
            locked: 0
        },
        userId: 0
    } as SimulationModel,
    reducers: {
        updateSimulation: (state, action: PayloadAction<SimulationModel>) => ({
            ...state,
            ...action.payload
        })
    }
})

export const { updateSimulation } = simulationSlicer.actions

export default simulationSlicer
