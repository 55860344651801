import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { fetchContractOptionsActions } from "./actions"

const contractOptions = createSlice({
    name: "contractOptions",
    initialState: {
        contractOptions: null as ContractOptionsModel[] | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchContractOptionsActions.pending.type]: (state) => {
            state.contractOptions = null
            state.isLoading = true
        },
        [fetchContractOptionsActions.fulfilled.type]: (state, action: PayloadAction<ContractOptionsAPI[]>) => {
            state.contractOptions = action.payload.map((item) => {
                return {
                    id: item.id,
                    symbol: item.symbol,
                    name: item.name,
                    status: item.status,
                    tradable: item.tradable,
                    expirationDate: item.expiration_date,
                    rootDymbol: item.root_symbol,
                    underlyingSymbol: item.underlying_symbol,
                    underlyingAssetId: item.underlying_asset_id,
                    type: item.type,
                    style: item.style,
                    strikePrice: item.strike_price,
                    size: item.size,
                    openInterest: item.open_interest,
                    openInterestDate: item.open_interest_date,
                    closePrice: item.close_price,
                    closePriceDate: item.close_price_date
                }
            })
            state.isLoading = false
        },
        [fetchContractOptionsActions.rejected.type]: (state) => {
            state.contractOptions = null
            state.isLoading = false
        }
    }
})

export default contractOptions
