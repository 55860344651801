import throttle from "lodash.throttle"

import onPageSeoRedisClient from "@lib/on-page-seo-redis"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const getOnPageSeoData = throttle(async () => {
    const getAllKeys = await onPageSeoRedisClient.keys("*")
    const getAllValues = await Promise.all(getAllKeys.map((key) => onPageSeoRedisClient.hgetall(key)))

    const getAllKeysAndValues = getAllKeys.map((key, index) => ({
        url: key,
        ...getAllValues[index]
    }))

    return getAllKeysAndValues as OnPageSeoDataAPI[]
}, 3600000)

export const fetchOnPageSeoData = createAsyncThunk("onPageSeoData/fetchOnPageSeoData", () => getOnPageSeoData())
