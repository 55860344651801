/* eslint-disable react/jsx-props-no-spreading */
import styled from "@emotion/styled"

import IconComponents from "./images"

export type IconType = keyof typeof IconComponents

const StyledIcon = styled.div`
    width: fit-content;
    height: fit-content;

    display: inline-flex;
    align-items: center;
    justify-content: center;
`

export interface IconsProps extends React.SVGProps<SVGSVGElement> {
    /**
     * Icon name
     */
    icon: IconType
    /**
     * wrapper classname
     */
    wrapperClassname?: string
}

const Icons: React.FC<IconsProps> = ({ icon, wrapperClassname, ...props }: IconsProps) => {
    const Component = IconComponents[icon]

    return (
        <StyledIcon data-testid='reku-icon' data-name={`icon-${icon}`} className={wrapperClassname}>
            <Component {...props} />
        </StyledIcon>
    )
}

Icons.defaultProps = {
    wrapperClassname: undefined,
    width: 16,
    height: 16
}

export default Icons
