import { HYDRATE } from "next-redux-wrapper"

import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import {
    addStockWatchlist,
    addWatchlist,
    fetchStockWatchlist,
    fetchWatchlist,
    removeStockWatchlist,
    removeWatchlist
} from "./actions"

const watchlistSlicer = createSlice({
    name: "watchlist",
    initialState: {
        watchlist: [] as WatchlistModel,
        stockWatchlist: [] as StockWatchlistModel,
        loading: false
    },
    reducers: {},
    extraReducers: {
        [fetchWatchlist.pending.type]: (state) => {
            state.loading = true
        },
        [fetchWatchlist.fulfilled.type]: (state, action: PayloadAction<WatchlistAPI>) => {
            state.loading = false
            if (action.payload) {
                state.watchlist = action.payload
            }
        },
        [addWatchlist.fulfilled.type]: (state, action: PayloadAction<AddWatchlistModel>) => {
            state.watchlist = action.payload
            state.loading = false
        },
        [removeWatchlist.fulfilled.type]: (state, action: PayloadAction<RemoveWatchlistModel>) => {
            state.watchlist = action.payload
            state.loading = false
        },
        // stock
        [fetchStockWatchlist.pending.type]: (state) => {
            state.loading = true
        },
        [fetchStockWatchlist.fulfilled.type]: (state, action: PayloadAction<StockWatchlistAPI>) => {
            state.loading = false
            if (action.payload) {
                state.stockWatchlist = action.payload
            }
        },
        [addStockWatchlist.fulfilled.type]: (state, action: PayloadAction<AddStockWatchlistModel>) => {
            state.stockWatchlist = action.payload
            state.loading = false
        },
        [removeStockWatchlist.fulfilled.type]: (state, action: PayloadAction<RemoveStockWatchlistModel>) => {
            state.stockWatchlist = action.payload
            state.loading = false
        },
        [HYDRATE]: (state, action) => {
            if (action.payload?.watchlist?.watchlist) {
                state.watchlist = action.payload?.watchlist?.watchlist
            }
        }
    }
})

export default watchlistSlicer
