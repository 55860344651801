/* eslint-disable import/prefer-default-export */
import axios from "axios"
import { createAsyncThunk } from "@reduxjs/toolkit"

import { isBrowser } from "@utils/browser"

export const fetchCareerDetail = createAsyncThunk<CareersAPI, string>("careerDetail/fetchCareerDetail", async (id) => {
    if (isBrowser) {
        const response = await axios.get(`/api/careers/${id}`)
        return response.data
    }

    const { getCareerById } = await import("@pages/api/careers/[careerId]")

    // eslint-disable-next-line no-underscore-dangle
    const careers = (await getCareerById(id))?._rawJson
    return careers
})
