import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export interface CurrencyPayload {
    s: string
}

export const fetchCurrency = createAsyncThunk<CurrencyAPI, CurrencyPayload>("curency/currency", async ({ s }) => {
    const response = await baseFetchApiV3({
        url: "/us-stock/currency",
        method: "GET",
        params: {
            s
        }
    })

    return response
})
