import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { fetchBalancesStock } from "./actions"

const balancesStockSlicer = createSlice({
    name: "balancesStock",
    initialState: {
        isLoading: false,
        balancesStock: null as BalancesStockModel[] | null
    },
    reducers: {},
    extraReducers: {
        [fetchBalancesStock.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchBalancesStock.fulfilled.type]: (state, action: PayloadAction<BalancesStockAPI[]>) => {
            state.balancesStock = action.payload
            state.isLoading = false
        }
    }
})

export default balancesStockSlicer
