import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export interface CancelOrderStockArgs {
    orderId: string
}

export const fetchCancelOrderStock = createAsyncThunk<string, CancelOrderStockArgs>(
    "history/cancelOrderStock",
    async ({ orderId }) => {
        const response = await baseFetchApiV3({
            url: `/us-stock/order/cancel/${orderId}`,
            method: "DELETE"
        })

        return response
    }
)
