import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const simpleTradeStockMarketFormSlicer = createSlice({
    name: "simpleTradeStockMarketForm",
    initialState: {
        isTouched: false,
        isTouchedLimit: false,
        error: "",
        errorLimit: "",
        amount: 0,
        estimated: 0,
        selectedPct: 0,
        limit: 0
    },
    reducers: {
        changeSimpleTradeStockMarketForm: (state, action: PayloadAction<SimpleTradeStockFormState>) => ({
            ...state,
            ...action.payload
        })
    }
})

export const { changeSimpleTradeStockMarketForm } = simpleTradeStockMarketFormSlicer.actions
export default simpleTradeStockMarketFormSlicer
