import { HYDRATE } from "next-redux-wrapper"

import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { fetchOnPageSeoData } from "./actions"

const onPageSeoSlicer = createSlice({
    name: "onPageSeo",
    initialState: {
        data: null as OnPageSeoDataModel[] | null
    },
    reducers: {},
    extraReducers: {
        [fetchOnPageSeoData.fulfilled.type]: (state, action: PayloadAction<OnPageSeoDataAPI[]>) => {
            state.data = action.payload?.map((item) => ({
                url: item.url,
                canonical_url: item?.canonical_url || null,
                title: {
                    id: item.title,
                    en: item.title_en
                },
                metaDescription: {
                    id: item.meta_description,
                    en: item.meta_description_en
                },
                noFollow: item.no_follow,
                noIndex: item.no_index
            }))
        },
        [HYDRATE]: (state, action: PayloadAction<any>) => ({
            ...state,
            ...action.payload.onPageSeo
        })
    }
})

export default onPageSeoSlicer
