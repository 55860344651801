import { createApi } from "@reduxjs/toolkit/query/react"
import { HYDRATE } from "next-redux-wrapper"

import fetchBaseQuery from "../../../services/baseQuery"

const feeApi = createApi({
    reducerPath: "feeApi",
    baseQuery: fetchBaseQuery,
    endpoints: (builder) => ({
        getFees: builder.query<FeesApi, void>({
            query: () => ({
                method: "GET",
                url: "/pages/fee"
            })
        })
    }),
    extractRehydrationInfo: (action, { reducerPath }) => {
        if (action.type === HYDRATE) {
            return action.payload[reducerPath]
        }

        return null
    }
})

export const { useGetFeesQuery } = feeApi
export default feeApi
