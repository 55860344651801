import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { fetchKYCDocument, KYCDocumentAPI } from "./actions"

interface KYCDocumentModel {
    identity: string
    selfie: string
    billsBank: string
    billsElectricity: string
}

const kycDocumentSlicer = createSlice({
    name: "kycDocument",
    initialState: {
        kycDocument: {} as KYCDocumentModel | null,
        isLoading: true
    },
    reducers: {},
    extraReducers: {
        [fetchKYCDocument.pending.type]: (state) => {
            state.kycDocument = null
            state.isLoading = true
        },
        [fetchKYCDocument.fulfilled.type]: (state, action: PayloadAction<KYCDocumentAPI>) => {
            state.kycDocument = {
                identity: action.payload.identity,
                selfie: action.payload.selfie,
                billsBank: action.payload.bills_bank,
                billsElectricity: action.payload.bills_electricity
            }
        }
    }
})

export default kycDocumentSlicer
