/* eslint-disable react/require-default-props */
/* eslint-disable react/default-props-match-prop-types */
import classNames from "classnames"
import tw, { styled } from "twin.macro"

import Icons from "../Icons"
import { IconsProps } from "../Icons/Icons"

const variants = {
    gray: {
        wrapper: tw`bg-background-strong text-main dark:text-dark-main dark:bg-dark-background-strong`,
        iconColor: `text-icon-contrast dark:text-dark-icon-contrast`
    },
    teal: {
        wrapper: tw`bg-foreground-teal text-primary dark:bg-dark-foreground-teal dark:text-dark-primary`,
        iconColor: `text-icon-primary dark:text-dark-icon-primary`
    },
    purple: {
        wrapper: tw`bg-foreground-purple text-secondary dark:bg-dark-foreground-purple dark:text-dark-secondary`,
        iconColor: `text-icon-secondary dark:text-dark-icon-secondary`
    },
    red: {
        wrapper: tw`bg-foreground-red text-error dark:bg-dark-foreground-red dark:text-dark-error`,
        iconColor: `text-icon-error dark:text-dark-icon-error`
    },
    yellow: {
        wrapper: tw`bg-foreground-yellow dark:bg-dark-foreground-yellow text-warning dark:text-dark-warning`,
        iconColor: `text-icon-warning dark:text-dark-icon-warning`
    },
    green: {
        wrapper: tw`bg-foreground-green dark:bg-dark-foreground-green text-success dark:text-dark-success`,
        iconColor: `text-icon-success dark:text-dark-icon-success`
    },
    blue: {
        wrapper: tw`bg-foreground-blue dark:bg-dark-foreground-blue text-info dark:text-dark-info`,
        iconColor: `text-icon-info dark:text-dark-icon-info`
    }
}

const sizes = {
    sm: tw`gap-0.5 text-[10px] py-[3px] leading-[14px] px-2 `,
    md: tw`gap-1 text-xs py-1 px-2.5 leading-4 `,
    lg: tw`gap-1 text-sm py-1 px-3 leading-5`
}

export type Variant = keyof typeof variants
export type Size = keyof typeof sizes

const defaultVariant: Variant = "gray"

const defaultSize: Size = "sm"

interface WrapperProps {
    /**
     * Badge variant
     */
    variant?: Variant
    /**
     * Badge size
     */
    size?: Size
}

const Wrapper = styled.div<WrapperProps>`
    ${tw`rounded-2xl font-semibold flex justify-center w-fit items-center`};
    ${({ variant }) => variants[variant || defaultVariant].wrapper};
    ${({ size }) => sizes[size || defaultSize]};
`

interface BadgeProps extends React.PropsWithChildren<WrapperProps> {
    /**
     * Class Name
     */
    className?: string
    /**
     * Icon
     */
    icon?: IconsProps["icon"]
    /**
     * Leading
     */
    iconPosition?: "leading" | "trailing"
    /**
     * id
     */
    id?: string
}

const Badge: React.FC<BadgeProps> = ({ variant, children, size, icon, iconPosition, className, id }: BadgeProps) => {
    // eslint-disable-next-line no-nested-ternary
    const iconSize = size === "sm" ? 12 : size === "md" ? 16 : size === "lg" ? 16 : 12
    const { iconColor } = variants[variant || defaultVariant]
    return (
        <Wrapper variant={variant} size={size} className={classNames("reku-new", className)} id={id}>
            {iconPosition === "leading" && icon && (
                <Icons className={iconColor} icon={icon} width={iconSize} height={iconSize} />
            )}
            {children}
            {iconPosition === "trailing" && icon && (
                <Icons className={iconColor} icon={icon} width={iconSize} height={iconSize} />
            )}
        </Wrapper>
    )
}

Badge.defaultProps = {
    variant: defaultVariant,
    size: defaultSize,
    icon: undefined,
    className: undefined,
    id: undefined
}

export default Badge
