import { baseFetchApiV3 } from "@api/baseApi"
import { fetchProfile } from "@redux/profile/action"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { imageCompression } from "@utils/image"

export interface KYCDocumentAPI {
    identity: string
    selfie: string
    bills_bank: string
    bills_electricity: string
}
export const submitKYCAction = createAsyncThunk<SuccessV2API, USSKYCPayload>(
    "uss-kyc/submitKYCAction",
    async (payload, { rejectWithValue, dispatch }) => {
        try {
            const response = await baseFetchApiV3({
                url: "/user/kyc/uss/verification",
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                data: payload
            })

            dispatch(fetchProfile())

            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)

export interface UploadArgs {
    file: File
    fileName?: string
}

export const uploadBillBank = createAsyncThunk<boolean, UploadArgs>(
    "uss-kyc/upload-bill-bank",
    async ({ file, fileName }) => {
        const imageCompressed = await imageCompression(file)
        const newImageFormat = new File([imageCompressed], fileName ?? imageCompressed.name, { type: "image/jpeg" })

        const formData = new FormData()
        formData.append("file", newImageFormat)
        formData.append("filename", fileName ?? "")

        const response = await baseFetchApiV3({
            url: "/user/kyc/uss/upload/bills/bank",
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: formData
        })

        return response
    }
)

export const uploadBillElectricity = createAsyncThunk<boolean, UploadArgs>(
    "uss-kyc/upload-bill-electricity",
    async ({ file, fileName }) => {
        const imageCompressed = await imageCompression(file)
        const newImageFormat = new File([imageCompressed], fileName ?? imageCompressed.name, { type: "image/jpeg" })

        const formData = new FormData()
        formData.append("file", newImageFormat)
        formData.append("filename", fileName ?? "")

        const response = await baseFetchApiV3({
            url: "/user/kyc/uss/upload/bills/electricity",
            method: "POST",
            headers: {
                "Content-Type": "multipart/form-data"
            },
            data: formData
        })

        return response
    }
)

export const fetchKYCDocument = createAsyncThunk<KYCDocumentAPI>("uss-kyc/fetchKYCDocument", async () => {
    const response = await baseFetchApiV3({
        url: "/user/kyc/document",
        method: "GET"
    })

    return response.data
})
