import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { fetchStatusMarketStock } from "./actions"

const statusMarketStockSlicer = createSlice({
    name: "statusMarket",
    initialState: {
        isLoading: true,
        statusMarket: null as StatusMarketStockModel | null
    },
    reducers: {},
    extraReducers: {
        [fetchStatusMarketStock.pending.type]: (state) => {
            state.isLoading = true
        },
        [fetchStatusMarketStock.fulfilled.type]: (state, action: PayloadAction<StatusMarketStockAPI>) => {
            state.isLoading = false
            state.statusMarket = {
                isOpen: action.payload.is_open,
                message: action.payload.message,
                nextClose: action.payload.next_close,
                nextOpen: action.payload.next_open,
                timestamp: action.payload.timestamp
            }
        }
    }
})

export default statusMarketStockSlicer
