import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const orderStockMarketBuyAction = createAsyncThunk<OrderStockMarketResultApi, OrderStockMarketPayload>(
    "orderStock/orderStockMarketAction",
    async (payload) => {
        const response = await baseFetchApiV3<OrderStockMarketResultApi>({
            url: `/us-stock/order/buy/market`,
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            data: payload
        })

        return response
    }
)
export const orderStockMarketSellAction = createAsyncThunk<OrderStockMarketResultApi, OrderStockMarketPayload>(
    "orderStock/orderStockMarketAction",
    async (payload) => {
        const response = await baseFetchApiV3<OrderStockMarketResultApi>({
            url: `/us-stock/order/sell/market`,
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            data: payload
        })

        return response
    }
)
