/* eslint-disable simple-import-sort/imports */
import { useRouter } from "next/router"
import { useEffect } from "react"
import { useTranslation } from "next-i18next"
import throttle from "lodash.throttle"

import {
    SIGN_IN,
    SIGN_IN_WITH_APPLE,
    SIGN_IN_WITH_GOOGLE,
    SIGN_UP_EMAIL_OTP_FAILED,
    SIGN_UP_EMAIL_OTP_SUCCESS,
    SIGN_UP_SUBMIT,
    SIGN_UP_WITH_APPLE,
    SIGN_UP_WITH_GOOGLE
} from "@const/moengage-analytic-event"

import {
    logout,
    login,
    login2fa,
    loginGoogle,
    register,
    resendOtp,
    verificationOtp,
    loginApple,
    loginCallback
} from "@redux/auth/action"
import { fetchGoogleUri } from "@redux/google/actions"
import { useAppDispatch, useAppSelector } from "@redux/hooks"
import { open2fa as open2faAction, close2fa as close2faAction } from "@redux/auth/slicer"
import { toast } from "@components/Toast"

import useNavigation from "./useNavigation"
import useGlobalLoading from "./useGlobalLoading"

const useAuth = () => {
    const auth = useAppSelector((state) => state.auth)

    return { auth }
}

export const useOpen2fa = () => {
    const auth = useAppSelector((state) => state.auth)
    const dispatch = useAppDispatch()

    const isOpen2fa = auth.open2fa || false

    const handleOpen2fa = () => {
        dispatch(open2faAction())
    }

    const handleClose2fa = () => {
        dispatch(close2faAction())
    }

    return { isOpen2fa, open2fa: handleOpen2fa, close2fa: handleClose2fa }
}

const getSource = (source: string) => {
    if (!source) return "Home"
    if (source.includes("register")) return "Sign Up"
    if (source.includes("wallet")) return "Wallet"
    if (source.includes("trade") || source.includes("lightning")) return "Trade"
    if (source.includes("settings")) return "Settings"

    return "Home"
}

export const useLogin = () => {
    const navigation = useNavigation()
    const dispatch = useAppDispatch()
    const router = useRouter()
    const redirect = router.query.redirect as string
    const error = router.query.error as string

    const { open2fa } = useOpen2fa()
    const { loading } = useGlobalLoading()

    const handleLogin = async (payload: LoginPayload) => {
        try {
            const res: any = await dispatch(login(payload))
            const data = res.payload

            if (!data?.token && data?.hash) {
                loading.stop()
                open2fa()
                return res
            }

            if (data) {
                const { default: moengageAnalytic } = await import("@lib/moengage-analytic")
                moengageAnalytic.track(SIGN_IN, {
                    source: getSource(redirect)
                })
            }

            return res
        } catch (err: any) {
            return err
        }
    }

    const handleLogin2fa = async (payload: Login2faPayload) => {
        try {
            const res: any = await dispatch(login2fa(payload))
            const data = res.payload

            if (data) {
                const { default: moengageAnalytic } = await import("@lib/moengage-analytic")
                moengageAnalytic.track(SIGN_IN, {
                    source: getSource(redirect)
                })
            }

            return res
        } catch (err: any) {
            return false
        }
    }

    useEffect(() => {
        if (error) {
            toast.error(error)
        }
    }, [error])

    useEffect(() => {
        if (redirect) {
            navigation.updateNavigation({
                from: router.pathname,
                redirect
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redirect, router])

    useEffect(() => {
        if (redirect) {
            router.prefetch(redirect, redirect)
            return
        }

        router.prefetch("/")
    }, [redirect, router])

    return {
        login: handleLogin,
        login2fa: handleLogin2fa
    }
}

export const useLoginCallback = () => {
    const router = useRouter()
    const navigation = useNavigation()
    const dispatch = useAppDispatch()

    const redirect = router.query.redirect as string

    const handleLoginCallback = async (payload: LoginCallbackPayload) => {
        try {
            const res: any = await dispatch(loginCallback(payload))

            return res
        } catch (err: any) {
            return err
        }
    }

    useEffect(() => {
        if (redirect) {
            navigation.updateNavigation({
                from: router.pathname,
                redirect
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redirect, router])

    useEffect(() => {
        if (redirect) {
            router.prefetch(redirect, redirect)
            return
        }

        router.prefetch("/")
    }, [redirect, router])

    return { handleLoginCallback }
}

export const useGoogleUri = () => {
    const { google, isLoading } = useAppSelector((state) => state.google)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(fetchGoogleUri())
    }, [dispatch])

    return {
        google,
        isLoading
    }
}

export const useLogout = () => {
    const dispatch = useAppDispatch()
    const resetLiveChatData = () => {
        window.LiveChatWidget.call("set_customer_email", " ")
        window.LiveChatWidget.call("set_customer_name", " ")
        window.LiveChatWidget.call("update_session_variables", {
            email: "",
            name: "",
            userId: "",
            id: ""
        })
    }

    const handleLogout = async (force: boolean = false) => {
        try {
            await dispatch(logout(force)).unwrap()
            resetLiveChatData()
        } catch (err: any) {
            toast.error(err?.message)
        }
    }

    return { logout: handleLogout }
}

export const useRegister = () => {
    const navigation = useNavigation()
    const dispatch = useAppDispatch()

    const router = useRouter()
    const redirect = router.query.redirect as string
    const error = router.query.error as string

    const handleRegister = async (payload: RegisterPayload) => dispatch(register(payload)).unwrap()

    useEffect(() => {
        if (error) {
            toast.error(error)
        }
    }, [error])

    useEffect(() => {
        if (redirect) {
            navigation.updateNavigation({
                from: router.pathname,
                redirect
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [redirect, router])

    // useEffect(() => {
    //     router.prefetch("/register/otp-verification")

    //     if (redirect) {
    //         router.prefetch(redirect, redirect)
    //         return
    //     }

    //     router.prefetch("/register/next-step")
    // }, [redirect, router])
    useEffect(() => {
        router.prefetch("/uss/register/verification")

        if (redirect) {
            router.prefetch(redirect, redirect)
            // return
        }

        // router.prefetch("/uss/register/next-step")
    }, [redirect, router])

    return { register: handleRegister }
}

export const useResend = () => {
    const dispatch = useAppDispatch()

    const handleResend = async (payload: ResendOtpPayload) => {
        try {
            const res: any = await dispatch(resendOtp(payload))
            return res
        } catch (err: any) {
            return false
        }
    }
    return { resend: handleResend }
}

export const useVerifyOtp = () => {
    const dispatch = useAppDispatch()

    const handlerVerify = async (payload: VerifyOTPProps) => {
        try {
            const res: any = await dispatch(verificationOtp(payload))
            const { default: moengageAnalytic } = await import("@lib/moengage-analytic")
            moengageAnalytic.track(SIGN_UP_SUBMIT, {
                source: "Login page"
            })
            moengageAnalytic.track(SIGN_UP_EMAIL_OTP_SUCCESS)

            return res
        } catch (err: any) {
            const { default: moengageAnalytic } = await import("@lib/moengage-analytic")
            moengageAnalytic.track(SIGN_UP_EMAIL_OTP_FAILED)

            return false
        }
    }

    return { verify: handlerVerify }
}

export const useGoogleAuth = () => {
    const router = useRouter()
    const dispatch = useAppDispatch()
    const { from, redirect } = useNavigation()
    const { auth } = useAuth()
    const { t } = useTranslation()
    const { open2fa } = useOpen2fa()

    // ===== SUCCESS =====
    const token = router.query?.token as string
    const hash = router.query?.hash as string
    const uid = router.query?.uid && Number(router.query?.uid)
    const isRegister = router.query?.is_register === "true"

    // ===== ERROR =====
    const errno = router.query?.errno as string
    const message = router.query?.message as string
    const code = router.query?.status_code as string

    useEffect(() => {
        if (router.isReady && auth.isLoggedIn) {
            toast.success(`${t("common:messages.already_logged_in")}`)

            if (redirect) {
                router.push(redirect)
                return
            }

            router.push(`/`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (hash && uid) {
            dispatch(
                loginGoogle({
                    hash,
                    token,
                    uid
                })
            )

            if (isRegister) {
                ;(async () => {
                    const { default: moengageAnalytic } = await import("@lib/moengage-analytic")
                    moengageAnalytic.track(SIGN_UP_WITH_GOOGLE)
                })()
                // router.replace("/register/next-step")
                router.replace("/uss/verification-status")

                return
            }

            ;(async () => {
                const { default: moengageAnalytic } = await import("@lib/moengage-analytic")
                moengageAnalytic.track(SIGN_IN_WITH_GOOGLE)
            })()

            if (!token) {
                router.replace("/login")
                open2fa()
                return
            }

            if (redirect) {
                router.replace(redirect)
                return
            }

            router.replace("/")
            return
        }

        if (errno) {
            // if (from.includes("/register")) {
            //     router.replace(`/register?error=${message}&redirect=${redirect}`)
            //     return
            // }
            if (from.includes("/uss/register")) {
                router.replace(`/uss/register?error=${message}&redirect=${redirect}`)
                return
            }

            router.replace(`/login?error=${message}&redirect=${redirect}`)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [code, errno, from, hash, isRegister, message, redirect, router, token, uid])

    useEffect(() => {
        if (hash && !token) {
            router.prefetch("/login")
        }

        if (hash && token && uid) {
            // if (isRegister) {
            //     router.prefetch("/register/next-step")
            // }
            // PHONE VALIDATION
            if (isRegister) {
                // router.prefetch("/register/next-step")
                router.replace("/uss/verification-status")
            }

            if (redirect) {
                router.prefetch(redirect, redirect)
                return
            }

            router.prefetch("/")
        }
    }, [isRegister, redirect, router, hash, token, uid])

    return {
        success: !errno
    }
}

const throttled = throttle((action: Function) => action(), 500, { trailing: false })

export const useAppleAuth = (idToken: string) => {
    const { t } = useTranslation()
    const router = useRouter()
    const dispatch = useAppDispatch()
    const { redirect } = useNavigation()
    const { auth } = useAuth()
    const { open2fa } = useOpen2fa()

    const appleSSO = async (token: string) => {
        try {
            const response = await dispatch(loginApple(token)).unwrap()

            if (response.hash && response.uid) {
                if (response.is_register) {
                    const { default: moengageAnalytic } = await import("@lib/moengage-analytic")
                    moengageAnalytic.track(SIGN_UP_WITH_APPLE)

                    // router.replace("/register/next-step")
                    // PHONE VALIDATION
                    // router.replace("/register/next-step")
                    router.replace("/uss/verification-status")

                    return
                }

                const { default: moengageAnalytic } = await import("@lib/moengage-analytic")
                moengageAnalytic.track(SIGN_IN_WITH_APPLE)

                if (!response.token) {
                    router.replace("/login")
                    open2fa()
                    return
                }

                if (redirect) {
                    router.replace(redirect)
                    return
                }

                router.replace("/")
            }
        } catch (err) {
            router.replace("/login")
        }
    }

    useEffect(() => {
        if (!idToken) {
            router.replace("/login")
            return
        }

        throttled(() => appleSSO(idToken))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!idToken) {
            router.prefetch("/login")
            return
        }

        if (redirect) {
            router.prefetch(redirect, redirect)
            return
        }

        router.prefetch("/")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (auth.isLoggedIn) {
            toast.success(`${t("common:messages.already_logged_in")}`)

            if (redirect) {
                router.push(redirect)
                return
            }

            router.push(`/`)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}

export default useAuth
