import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { orderStockLimitBuyAction, orderStockLimitSellAction } from "./actions"

const orderStockLimitSlicer = createSlice({
    name: "orderStockLimitSlicer",
    initialState: {
        orderStockLimitResult: null as OrderStockLimitResultModel | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [orderStockLimitBuyAction.pending.type]: (state) => {
            state.orderStockLimitResult = null
            state.isLoading = true
        },
        [orderStockLimitBuyAction.fulfilled.type]: (state, action: PayloadAction<OrderStockLimitResultApi>) => {
            state.orderStockLimitResult = {
                amount: action.payload.amount,
                price: action.payload.price,
                quantity: action.payload.quantity,
                quote: action.payload.quote,
                side: action.payload.side,
                status: action.payload.status,
                createdAt: action.payload.created_at,
                symbol: action.payload.symbol,
                transactionId: action.payload.transaction_id
            }
            state.isLoading = false
        },
        [orderStockLimitSellAction.pending.type]: (state) => {
            state.orderStockLimitResult = null
            state.isLoading = true
        },
        [orderStockLimitSellAction.fulfilled.type]: (state, action: PayloadAction<OrderStockLimitResultApi>) => {
            state.orderStockLimitResult = {
                amount: action.payload.amount,
                price: action.payload.price,
                quantity: action.payload.quantity,
                quote: action.payload.quote,
                side: action.payload.side,
                status: action.payload.status,
                createdAt: action.payload.created_at,
                symbol: action.payload.symbol,
                transactionId: action.payload.transaction_id
            }
            state.isLoading = false
        }
    }
})

export default orderStockLimitSlicer
