import classNames from "classnames"
import tw, { styled } from "twin.macro"

import { Case, Default, Switch } from "@components/If"

const DividerStrong = styled.div`
    ${tw`w-full border-t border-t-grey-200 dark:border-t-dark-grey-300`}
`
const DividerSubtle = styled.div`
    ${tw`w-full border-t border-t-grey-100 dark:border-t-dark-grey-100`}
`

export interface DividerProps extends Omit<React.HTMLProps<HTMLHRElement>, "as"> {
    /**
     * Set variant divider
     * @default "subtle"
     */
    variant?: "subtle" | "strong"
    /**
     * classname of divider
     */
    className?: string
}

const Divider: React.FC<DividerProps> = ({ variant, className }) => (
    <Switch>
        <Case condition={variant === "strong"}>
            <DividerStrong className={classNames("reku-new", className)} />
        </Case>
        <Default>
            <DividerSubtle className={classNames("reku-new", className)} />
        </Default>
    </Switch>
)

Divider.defaultProps = {
    variant: "subtle",
    className: undefined
}

export default Divider
