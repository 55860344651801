import { BaseQueryFn } from "@reduxjs/toolkit/query"
import axios, { AxiosRequestConfig, AxiosError } from "axios"

import { HELP_API_URL } from "@config/config"

export interface HelpBaseQueryMeta {
    headers: {
        ["x-wp-totalpages"]: string
    }
}

interface Args {
    url: string
    method: AxiosRequestConfig["method"]
    data?: AxiosRequestConfig["data"]
}

const fetchBaseQuery: BaseQueryFn<Args | Args[], unknown, unknown> = async (args) => {
    const request = Array.isArray(args) ? args : [args]

    try {
        const results = await Promise.all(
            request.map(({ url, method, data }) =>
                axios({
                    url: `${HELP_API_URL}${url}`,
                    method,
                    data
                })
            )
        )

        if (!Array.isArray(results[0].data)) {
            return { data: results[0].data, meta: { headers: results[0].headers } }
        }

        const arrays = results.map((result) => result.data) as []
        const merged = [].concat(...arrays)

        return { data: merged, meta: { headers: results[0].headers } }
    } catch (axiosError) {
        const err = axiosError as AxiosError

        return {
            error: { status: err.response?.status, data: err.response?.data }
        }
    }
}

export default fetchBaseQuery
