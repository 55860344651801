import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { HYDRATE } from "next-redux-wrapper"
import { fetchCareerDetail } from "./actions"

const careerDetailSlicer = createSlice({
    name: "careerDetail",
    initialState: {
        careerDetail: null as CareersAPI | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [fetchCareerDetail.pending.type]: (state) => {
            state.isLoading = true
            state.careerDetail = null
        },
        [fetchCareerDetail.fulfilled.type]: (state, action: PayloadAction<CareersAPI>) => {
            state.careerDetail = action.payload
            state.isLoading = false
        },
        [HYDRATE]: (state, action: PayloadAction<{ careerDetail: { careerDetail: CareersAPI } }>) => {
            if (action.payload?.careerDetail?.careerDetail) {
                state.careerDetail = action.payload.careerDetail.careerDetail
            }
        }
    }
})

export default careerDetailSlicer
