import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

interface FetchHistoryStockArgs {
    is_usd?: boolean
    page: number
}

export const handleFetchHistoryStock = async ({ page, is_usd }: FetchHistoryStockArgs) => {
    const params: object = {
        is_usd,
        page
    }

    const response = await baseFetchApiV3({
        url: "/us-stock/order/history",
        method: "GET",
        params
    })

    return response
}

export const fetchHistoryStock = createAsyncThunk<HistoryStockApi[], FetchHistoryStockArgs>(
    "history/fetchHistoryStock",
    handleFetchHistoryStock
)
