import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const orderStockLimitBuyAction = createAsyncThunk<OrderStockLimitResultApi, OrderStockLimitPayload>(
    "orderStock/orderStockLimitBuyAction",
    async (payload) => {
        const response = await baseFetchApiV3<OrderStockLimitResultApi>({
            url: `/us-stock/order/buy/limit`,
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            data: payload
        })

        return response
    }
)
export const orderStockLimitSellAction = createAsyncThunk<OrderStockLimitResultApi, OrderStockLimitPayload>(
    "orderStock/orderStockLimitSellAction",
    async (payload) => {
        const response = await baseFetchApiV3<OrderStockLimitResultApi>({
            url: `/us-stock/order/sell/limit`,
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            data: payload
        })

        return response
    }
)
