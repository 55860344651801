import { createApi } from "@reduxjs/toolkit/query/react"
import { HYDRATE } from "next-redux-wrapper"

import fetchBaseQuery from "./helpBaseQuery"

const helpDetailPostApi = createApi({
    reducerPath: "helpDetailpostApi",
    baseQuery: fetchBaseQuery,
    tagTypes: ["Posts"],
    endpoints: (builder) => ({
        getHelpDetailPost: builder.query<DetailPostModel[], void | string>({
            query: (slug) => [
                {
                    method: "GET",
                    url: `posts?_embed&slug=${slug}`
                },
                {
                    method: "GET",
                    url: `posts?_embed&meta_key=_wp_old_slug&meta_value=${slug}`
                }
            ],
            providesTags: (result) =>
                result
                    ? [...result.map(({ id }) => ({ type: "Posts" as const, id })), { type: "Posts", id: "LIST" }]
                    : [{ type: "Posts", id: "LIST" }]
        })
    }),
    extractRehydrationInfo: (action, { reducerPath }) => {
        if (action.type === HYDRATE) {
            return action.payload[reducerPath]
        }

        return null
    }
})

export const { useGetHelpDetailPostQuery } = helpDetailPostApi
export default helpDetailPostApi
