import React from "react"
import { useRouter } from "next/router"
import { useTranslation } from "next-i18next"
import { useMediaQuery } from "react-responsive"
import tw from "twin.macro"

import Link from "@components/Link"
import Container from "@components/v2/Container"
import Divider from "@components/v2/Divider"
import Space from "@layouts/Space"

const StyledContainer = tw(Container)`
    flex flex-col
    xl:pb-10 pb-4
    xl:gap-8 gap-4

`

const Wrapper = tw.div`
    flex xl:flex-row flex-col-reverse
    xl:justify-between items-center
    gap-4
`

const FooterStock = () => {
    const { t } = useTranslation("components")
    const router = useRouter()
    const isMobile = useMediaQuery({ maxWidth: 1279 })
    const coinCode = String(router.query.symbol)

    const lightPath = `/lightning/price/${coinCode}`
    const kycPath = ["/uss/kyc/[term]"]

    const isLightPath = Boolean(lightPath === router.asPath)
    const isKycPath = Boolean(kycPath.find((e) => e === router.pathname))

    if ((isLightPath && isMobile) || isKycPath) {
        return null
    }

    return (
        <StyledContainer className='reku-new'>
            <Divider />
            <Wrapper>
                <span className='xl:paragraph-2 paragraph-4 text-additional dark:text-dark-additional'>
                    {t("footer.us_stock.copy_right")}
                </span>
                <Space direction='horizontal' className='xl:!gap-4'>
                    <Link href='/uss/term'>
                        <span className='xl:title-3 title-5 text-additional dark:text-dark-additional'>
                            {t("footer.us_stock.term")}
                        </span>
                    </Link>
                    <Link href='/uss/privacy'>
                        <span className='xl:title-3 title-5 text-additional dark:text-dark-additional'>
                            {t("footer.us_stock.privacy")}
                        </span>
                    </Link>
                </Space>
            </Wrapper>
        </StyledContainer>
    )
}

export default FooterStock
