/* eslint-disable simple-import-sort/imports */
import feeApi from "@app/Fees/services/fee"
import helpPostsApi from "@app/Help/services/helpPosts"
import helpDetailPostApi from "@app/Help/services/helpDetailPost"
import helpRedirection from "@app/Help/services/helpRedirection"
import faqPostsApi from "@app/Faq/services/faqPosts"

const middlewares = [
    feeApi.middleware,
    helpPostsApi.middleware,
    helpDetailPostApi.middleware,
    helpRedirection.middleware,
    faqPostsApi.middleware
]

export default middlewares
