import fetchApi from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

interface Args {
    page?: number
    limit?: number
    coin?: number
    type?: number
    start_date?: string
    end_date?: string
}

export const fetchOTCHistory = createAsyncThunk<OTCHistoryAPI[], Args>(
    "OTCHistory/fetchOTCHistory",
    async (args = {}) => {
        const response = await fetchApi({
            url: "/otc/history",
            method: "GET",
            params: {
                page: args.page,
                limit: args.limit,
                type: args.type,
                coin: args.coin,
                start_date: args.start_date,
                end_date: args.end_date
            }
        })

        return response
    }
)

export const fetchOTCHistoryPagination = createAsyncThunk<{ data: OrderHistoryAPI; offset: number }, Args>(
    "OTCHistory/fetchOTCHistoryPagination",
    async (args = {}) => {
        const response = await fetchApi({
            url: "/otc/history",
            method: "GET",
            params: {
                page: args.page,
                limit: args.limit,
                type: args.type,
                coin: args.coin,
                start_date: args.start_date,
                end_date: args.end_date
            }
        })

        return response
    }
)
