import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { fetchHistoryStock } from "./actions"

const historyStockSlicer = createSlice({
    name: "historyStock",
    initialState: {
        stockLoading: false,
        historyStock: null as HistoryStockModel | null
    },
    reducers: {},
    extraReducers: {
        [fetchHistoryStock.pending.type]: (state) => {
            state.stockLoading = true
        },
        [fetchHistoryStock.fulfilled.type]: (state, action: PayloadAction<HistoryStockApi>) => {
            state.stockLoading = false
            state.historyStock = {
                data: action.payload.data,
                metadata: action.payload.metadata
            }
        }
    }
})

export default historyStockSlicer
