import { baseFetchApiV3 } from "@api/baseApi"
import { createAsyncThunk } from "@reduxjs/toolkit"

export interface BuySellArgs {
    amount: number
    symbol?: string
    d?: boolean
}

export const buyUsd = createAsyncThunk<SuccessAPI, BuySellArgs>(
    "buySellUsd/buyUsd",
    async ({ amount, symbol, d }, { rejectWithValue }) => {
        try {
            const response = await baseFetchApiV3<SuccessAPI>({
                url: "/us-stock/wallet/deposit",
                method: "POST",
                data: {
                    amount,
                    symbol
                },
                params: {
                    d
                },
                headers: {
                    "Content-Type": "application/json"
                }
            })

            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
export const sellUsd = createAsyncThunk<SuccessAPI, BuySellArgs>(
    "buySellUsd/sellUsd",
    async ({ amount, symbol }, { rejectWithValue }) => {
        try {
            const response = await baseFetchApiV3<SuccessAPI>({
                url: "/us-stock/wallet/withdraw",
                method: "POST",
                data: {
                    amount,
                    symbol
                },
                headers: {
                    "Content-Type": "application/json"
                }
            })

            return response
        } catch (error) {
            return rejectWithValue(error)
        }
    }
)
