import { createSlice, PayloadAction } from "@reduxjs/toolkit"

const simpleTradeStockPreferences = createSlice({
    name: "simpleTradeStockPreferences",
    initialState: {
        orderType: "buy" as OrderType,
        typeTab: "Market" as OrderTypeTab,
        unit: "usd" as UnitStock,
        showPreview: false,
        isSuccess: false
    },
    reducers: {
        changeSimpleTradeStockPreference: (state, action: PayloadAction<SimpleTradeStockPreferencesModel>) => ({
            ...state,
            ...action.payload
        })
    }
})

export const { changeSimpleTradeStockPreference } = simpleTradeStockPreferences.actions
export default simpleTradeStockPreferences
