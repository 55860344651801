import { createSlice, PayloadAction } from "@reduxjs/toolkit"

import { buyUsd, sellUsd } from "./actions"

const buySellUsdSlicer = createSlice({
    name: "buySellUsd",
    initialState: {
        buyUsd: null as BuySellUsdModel | null,
        sellUsd: null as BuySellUsdModel | null,
        isLoading: false
    },
    reducers: {},
    extraReducers: {
        [buyUsd.pending.type]: (state) => {
            state.isLoading = true
            state.buyUsd = null
        },
        [buyUsd.fulfilled.type]: (state, action: PayloadAction<BuySellUsdAPI>) => {
            state.isLoading = false
            state.buyUsd = {
                amount: action.payload.amount,
                price: action.payload.price
            }
        },
        [buyUsd.rejected.type]: (state) => {
            state.isLoading = false
            state.buyUsd = null
        },
        [sellUsd.pending.type]: (state) => {
            state.isLoading = true
            state.sellUsd = null
        },
        [sellUsd.fulfilled.type]: (state, action: PayloadAction<BuySellUsdAPI>) => {
            state.isLoading = false
            state.sellUsd = {
                amount: action.payload.amount,
                price: action.payload.price
            }
        },
        [sellUsd.rejected.type]: (state) => {
            state.isLoading = false
            state.sellUsd = null
        }
    }
})

export default buySellUsdSlicer
